import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth'

import { OLoader, OError } from '@edwin/react-web-admin'
import { auth } from '@services/firebase'

export default function PSignInWithEmailLink() {
  const [isError, setIsError] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      var email = window.localStorage.getItem('emailForSignIn')
      if (!email) {
        email = window.prompt('Please provide your email for confirmation')
      }

      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          window.localStorage.removeItem('emailForSignIn')
          setIsError(false)
        })
        .catch((error) => {
          console.log(error)
          setIsError(true)
        })
    }
  }, [location?.pathname])

  if (isError) {
    return (
      <div className="h-screen flex items-center justify-center">
        <OError />
      </div>
    )
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center">
      <OLoader />
    </div>
  )
}
