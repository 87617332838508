import React, { useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

import {
  AButton,
  AFormWrapper,
  MFormSelect,
  MFormOrganizationsCheckboxList,
  MFormInput,
} from '@edwin/react-web-admin'

import OPageWrapper from '@organisms/OPageWrapper'

import { addAdminUser } from '@services/users'
import { useOrganizations } from '@services/organizations'

import ROUTES, { generateRoute } from '@const/Routes'

const PAddAdminUser = () => {
  const navigate = useNavigate()
  const [adminUserData, setAdminUserData] = useState()
  const [isSaving, setIsSaving] = useState(false)
  const [allDone, setAllDone] = useState(false)
  const [submitError, setSubmitError] = useState()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [organizations] = useOrganizations()

  const {
    register,
    watch,
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    defaultValues: { ...adminUserData },
  })

  const isSuperAdminValueChecked = watch('isSuperAdmin')

  const breadcrumbs = [{ type: 'home', navigateTo: ROUTES.ADMIN_USERS }, { name: 'Add Admin User' }]

  const [nameField, lastNameField, emailField, aclField, aclTeamsField, adminRoleField] = watch([
    'firstName',
    'lastName',
    'email',
    'acl',
    'aclTeams',
    'adminRole',
  ])

  useEffect(() => {
    setShowConfirmation(false)
  }, [nameField, lastNameField, emailField, aclField, aclTeamsField, adminRoleField])

  useEffect(() => {
    if (allDone) {
      setTimeout(() => {
        navigate(generateRoute(ROUTES.ADMIN_USERS))
      }, 2000)
    }
  }, [allDone, reset, navigate])

  const handleAdminUserFormSubmit = data => {
    if (!data.isSuperAdmin && !Object.values(data.acl || {}).filter(val => val).length) {
      setError('acl', { type: 'manual', message: 'One or more organizations must be selected' })

      return
    }

    setSubmitError(null)
    setAdminUserData(data)
    setShowConfirmation(true)
  }

  const saveUserData = useCallback(async () => {
    setIsSaving(true)

    try {
      await addAdminUser(adminUserData)
      setIsSaving(false)
      setAllDone(true)
    } catch (err) {
      setSubmitError(err?.message)
      setIsSaving(false)
    }
  }, [adminUserData])

  return (
    <OPageWrapper breadcrumbs={breadcrumbs}>
      <AFormWrapper title="Add Admin User">
        <form onSubmit={handleSubmit(handleAdminUserFormSubmit)} method="POST">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <MFormInput
                  id="firstName"
                  name="firstName"
                  label="First name"
                  errorMessage={errors?.firstName?.message}
                  disabled={isSaving || allDone}
                  {...register('firstName', {
                    required: {
                      value: true,
                      message: 'This field is required',
                    },
                  })}
                />
              </div>
              <div className="sm:col-span-4">
                <MFormInput
                  id="lastName"
                  name="lastName"
                  label="Last name"
                  errorMessage={errors?.lastName?.message}
                  disabled={isSaving || allDone}
                  {...register('lastName', {
                    required: { value: true, message: 'This field is required' },
                  })}
                />
              </div>
              <div className="sm:col-span-4">
                <MFormInput
                  id="email"
                  name="email"
                  label="Email address"
                  errorMessage={errors?.email?.message}
                  disabled={isSaving || allDone}
                  {...register('email', {
                    required: 'Invalid email address',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    },
                  })}
                />
              </div>
              <div className="sm:col-span-4">
                <Controller
                  name="isSuperAdmin"
                  register={register('isSuperAdmin')}
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => {
                    const values = {
                      superAdmin: { name: 'Super Admin', id: 'superAdmin' },
                      admin: { name: 'Organization Admin', id: 'admin' },
                    }

                    return (
                      <MFormSelect
                        id="role"
                        name="role"
                        label="Role"
                        errorMessage={error?.message}
                        items={[values.superAdmin, values.admin]}
                        className="w-1/2 mt-1"
                        defaultValue={values.admin}
                        onChange={value => {
                          onChange(value?.id === 'superAdmin')
                        }}
                      />
                    )
                  }}
                />
              </div>
              {!isSuperAdminValueChecked && (
                <div className="sm:col-span-4">
                  <div className="block text-xl font-medium leading-6 text-gray-900">
                    Organizations Access
                  </div>
                  <div className="mt-2">
                    <Controller
                      name="acl"
                      register={register('acl', {
                        required: {
                          value: true,
                          message: 'One or more organizations must be selected',
                        },
                      })}
                      control={control}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <MFormOrganizationsCheckboxList
                          organizations={organizations}
                          aclValues={value}
                          onChange={onChange}
                          errorMessage={error?.message}
                        />
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <div className="flex flex-col items-end">
              {allDone && !submitError ? (
                <div className="rounded-md px-3 py-2 text-center text-sm font-semibold text-white shadow-sm bg-accent">
                  All done{' '}
                  <span role="img" aria-label="confetti">
                    🎉
                  </span>
                </div>
              ) : (
                <>
                  {showConfirmation ? (
                    <div className="flex items-center space-x-4">
                      <p className="whitespace-nowrap text-sm text-right leading-5 text-gray-600">
                        Are you sure?
                      </p>
                      <AButton
                        secondary
                        onClick={() => {
                          setShowConfirmation(false)
                        }}
                      >
                        Cancel
                      </AButton>
                      <AButton
                        isLoading={isSaving}
                        className="whitespace-nowrap"
                        onClick={saveUserData}
                      >
                        Yes, save and invite
                      </AButton>
                    </div>
                  ) : (
                    <div>
                      <AButton type="submit">Save</AButton>
                    </div>
                  )}
                  {!!submitError && (
                    <span className="pt-1 text-sm leading-5 font-medium rounded-md text-red-500">
                      {submitError}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        </form>
      </AFormWrapper>
    </OPageWrapper>
  )
}

export default PAddAdminUser
