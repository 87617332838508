import React, { useState, useCallback, useEffect } from 'react'
import {
  useForm,
  // Controller
} from 'react-hook-form'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

import {
  //  MFormSelect,
  AFormWrapper,
  MFormInput,
  AButton,
} from '@edwin/react-web-admin'

import OPageWrapper from '@organisms/OPageWrapper'

import { useOrganization } from '@services/organizations'
import { addTeam } from '@services/teams'

import ROUTES, { generateRoute } from '@const/Routes'

const PAddOrganizationTeam = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { organizationId } = useParams()
  const [organization = location?.state.organization, isOrganizationLoading] =
    useOrganization(organizationId)

  const [teamData, setTeamData] = useState()
  const [isSaving, setIsSaving] = useState(false)
  const [allDone, setAllDone] = useState(false)
  const [error, setError] = useState()
  const [showConfirmation, setShowConfirmation] = useState(false)

  const {
    // control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    defaultValues: {
      ...teamData,
      // isLeaderboardAvailable: true,
    },
  })

  const isLoading = isOrganizationLoading

  const breadcrumbs = [
    { type: 'home', navigateTo: ROUTES.ROOT },
    {
      name: organization?.name,
      navigateTo: generateRoute(ROUTES.ORGANIZATION, { organizationId }),
      state: { organization },
    },
    { name: 'Add Group' },
  ]

  const [nameField, isLeaderboardAvailableField] = watch(['name', 'isLeaderboardAvailable'])

  useEffect(() => {
    setShowConfirmation(false)
  }, [nameField, isLeaderboardAvailableField])

  useEffect(() => {
    if (allDone) {
      setTimeout(() => {
        navigate(generateRoute(ROUTES.ORGANIZATION, { organizationId }), {
          state: { organization },
        })
      }, 4000)
    }
  }, [allDone, navigate, organization, organizationId, reset])

  const handleTeamFormSubmit = data => {
    setError(null)
    setTeamData({
      ...data,
      organizationId,
    })
    setShowConfirmation(true)
  }

  const saveTeamData = useCallback(async () => {
    setIsSaving(true)

    try {
      await addTeam(teamData)
      setIsSaving(false)
      setAllDone(true)
    } catch (err) {
      setError(err?.message)
      setIsSaving(false)
    }
  }, [teamData])

  return (
    <OPageWrapper breadcrumbs={breadcrumbs} isLoading={isLoading}>
      <AFormWrapper title="Add group to organization">
        <form onSubmit={handleSubmit(handleTeamFormSubmit)} method="POST">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <MFormInput
                  id="name"
                  name="name"
                  label="Name"
                  errorMessage={errors?.name?.message}
                  disabled={isSaving || allDone}
                  {...register('name', {
                    required: {
                      value: true,
                      message: 'This field is required',
                    },
                  })}
                />
              </div>
              {/* <div className="sm:col-span-4">
                <Controller
                  name="isLeaderboardAvailable"
                  register={register('isLeaderboardAvailable')}
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => {
                    const values = {
                      allowed: { name: 'Allowed', id: 'allowed' },
                      notAllowed: { name: 'Not allowed', id: 'not-allowed' },
                    }

                    return (
                      <MFormSelect
                        label="Leaderboards"
                        items={[values.allowed, values.notAllowed]}
                        className="w-1/2 mt-1"
                        defaultValue={value ? values.allowed : values.notAllowed}
                        onChange={value => {
                          onChange(value?.id === 'allowed')
                        }}
                      />
                    )
                  }}
                />
              </div> */}
            </div>
          </div>
          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <div className="flex flex-col items-end">
              {allDone && !error ? (
                <div className="rounded-md px-3 py-2 text-center text-sm font-semibold text-white shadow-sm bg-accent">
                  All done{' '}
                  <span role="img" aria-label="confetti">
                    🎉
                  </span>
                </div>
              ) : (
                <>
                  {showConfirmation ? (
                    <div className="flex items-center space-x-4">
                      <p className="whitespace-nowrap text-sm text-right leading-5 text-gray-600">
                        Are you sure?
                      </p>
                      <AButton
                        secondary
                        onClick={() => {
                          setShowConfirmation(false)
                        }}
                      >
                        Cancel
                      </AButton>
                      <AButton
                        isLoading={isSaving}
                        className="whitespace-nowrap"
                        onClick={saveTeamData}
                      >
                        Yes, add new group
                      </AButton>
                    </div>
                  ) : (
                    <div>
                      <AButton type="submit">Save</AButton>
                    </div>
                  )}
                  {!!error && (
                    <span className="pt-1 text-sm leading-5 font-medium rounded-md text-red-500">
                      {error}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        </form>
      </AFormWrapper>
    </OPageWrapper>
  )
}

export default PAddOrganizationTeam
