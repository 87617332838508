import { DateTime } from 'luxon'
import { phone } from 'phone'
import ShortUniqueId from 'short-unique-id'

export const sleep = async (ms = 0) => {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

export const generateId = prefix => {
  return new ShortUniqueId({ length: 15 })()
}

export const mapFirebaseMissionAndSerie = (
  currentMissionOrSerie,
  fullMissionsArray,
  fullSeriesArray
) => {
  const tmsId = currentMissionOrSerie?.id || currentMissionOrSerie?._id

  const isAssigned = currentMissionOrSerie?.isAssigned !== false

  let fullMissionOrSerie =
    fullMissionsArray.find(mission => mission.id === tmsId) ||
    fullSeriesArray.find(series => series.id === tmsId)

  if (!fullMissionOrSerie) return null

  if (!fullMissionOrSerie.type) {
    fullMissionOrSerie.type = fullMissionOrSerie._type
      ? fullMissionOrSerie._type
      : fullMissionOrSerie.missions?.length
      ? 'userMissions'
      : 'topicSeries'
  }

  if (fullMissionOrSerie.type === 'topicSeries' && fullMissionOrSerie.missions) {
    fullMissionOrSerie.missions = fullMissionOrSerie.missions.map(missionDeep => {
      const deepMissionId = missionDeep.id || missionDeep._id

      let fullSerieMission = fullMissionsArray.find(mission => mission.id === deepMissionId)

      return fullSerieMission
    })
  }

  return {
    ...currentMissionOrSerie,
    isAssigned,
    // description: 'Overview of the assignments and why being active is important.', //TODO: remove once description for missions s provided
    ...fullMissionOrSerie,
  }
}

export const parseTimestampDateFields = (
  object,
  timestampFieldsToDate // ['createdOn', 'updatedOn']
) => {
  const parsedObject = { ...object }

  for (const timestampField of timestampFieldsToDate) {
    if (!timestampField) continue

    const timestampNumeric = parseInt(parsedObject[timestampField], 10)

    if (timestampNumeric) {
      parsedObject[timestampField] = parseInt(parsedObject[timestampField], 10)
      parsedObject[`${timestampField}Date`] = DateTime.fromMillis(
        parsedObject[timestampField]
      ).toISO()
    }

    // temporary fix because for some reason edwin cloud returns firebase timestamp as an object sometimes
    if (parsedObject[timestampField]?._seconds) {
      const firebaseTimestampNumeric = parsedObject[timestampField]?._seconds * 1000
      parsedObject[timestampField] = firebaseTimestampNumeric
      parsedObject[`${timestampField}Date`] = DateTime.fromMillis(firebaseTimestampNumeric).toISO()
    }
  }

  return parsedObject
}

export const arrayToObj = (array, setDefaultValue) => {
  if (!array.length) return null

  return array.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.id]:
        setDefaultValue === undefined || setDefaultValue === null
          ? curr
          : _.isFunction(setDefaultValue)
          ? setDefaultValue(curr)
          : setDefaultValue,
    }
  }, {})
}

export const sortTopicsArray = (a, b) => {
  // Sort by priority first
  const priorityA = a.priority !== undefined ? a.priority : Number.MAX_SAFE_INTEGER
  const priorityB = b.priority !== undefined ? b.priority : Number.MAX_SAFE_INTEGER

  if (priorityA < priorityB) return -1
  if (priorityA > priorityB) return 1

  // If priority is the same, sort by name
  if (a.name < b.name) return -1
  if (a.name > b.name) return 1

  return 0
}

export const validatePhoneNumber = phoneNumber => {
  const validatedPhoneUSA = phone(phoneNumber, { country: 'USA' })
  const validatedPhonePOL = phone(phoneNumber, { country: 'POL' })

  //fallback for POL
  if (validatedPhonePOL.isValid) return validatedPhonePOL

  return validatedPhoneUSA
}
