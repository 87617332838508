import { doc, setDoc, updateDoc, collection, updateDoc } from 'firebase/firestore'

import { getAxiosInstance } from './axios.js'
import { auth, firestoreDb, COLLECTIONS } from './firebase.js'
import { DateTime } from 'luxon'
import { generateId } from '../utils/helpers.js'

export const addUserNote = async ({ userId, note }) => {
  if (!userId) {
    throw new Error('No userId available')
  }

  if (!note) {
    throw new Error('No note available')
  }

  const id = generateId()
  const notesRef = doc(firestoreDb, COLLECTIONS.userNotes, id)

  return await setDoc(notesRef, {
    userId,
    createdBy: auth.currentUser.uid,
    createdOn: DateTime.now().toJSDate(),
    note: note,
    id,
  })
}

export const editUserNote = async ({ id, userId, note }) => {
  if (!id) {
    throw new Error('No note id available')
  }

  if (!userId) {
    throw new Error('No userId available')
  }

  if (!note) {
    throw new Error('No note available')
  }

  const noteRef = doc(firestoreDb, COLLECTIONS.userNotes, id)

  return await updateDoc(noteRef, {
    userId,
    updatedBy: auth.currentUser.uid,
    updatedOn: DateTime.now().toJSDate(),
    note: note,
  })
}
