import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Route, Routes, useParams, useNavigate, useMatch, useLocation } from 'react-router-dom'
import classnames from 'classnames'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'

import {
  AButton,
  OSlideOver,
  OUsersList,
  OEditTeam,
  OOrganizationTeamsList,
} from '@edwin/react-web-admin'
import OPageWrapper from '@organisms/OPageWrapper'

import { useOrganization } from '@services/organizations'
import {
  useTeam,
  useUsersForTeam,
  updateTeamTopics,
  updateTeam,
  useTeamsForOrganization,
} from '@services/teams'
import { fetchAllContent } from '@services/content'
import useStore from '@store/useStore'

import { mapFirebaseMissionAndSerie, sleep } from '@utils/helpers'
import ROUTES, { generateRoute } from '@const/Routes'
import { DateTime } from 'luxon'

import { generateTeamQuestionnaireReportFBFunction } from '@services/firebase'

const POrganizationTeam = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const matchUsersRoute = useMatch(ROUTES.ORGANIZATION_TEAM_USERS)
  const { organizationId, teamId } = useParams()
  const [organization = location?.state?.organization, isOrganizationLoading] =
    useOrganization(organizationId)
  const [team = location?.state?.team, isTeamLoading] = useTeam(teamId)
  const [users, isUsersLoading] = useUsersForTeam(teamId)
  const [teamTopics, setTeamTopics] = useState()
  const [fullContent, setFullContent] = useState()
  const [isFullContentLoading, setIsFullContentLoading] = useState(false)
  const [isTeamTopicsLoading, setIsTeamTopicsLoading] = useState(false)
  const { isSuperAdmin } = useStore(state => ({
    isSuperAdmin: state.isSuperAdmin,
  }))
  const [isEditTeamSlideOverOpen, setIsEditTeamSlideOverOpen] = useState(false)
  const [isCSVDownloading, setIsCSVDownloading] = useState(false)
  const [csvDownloadError, setCsvDownloadError] = useState(null)
  const tabs = useMemo(
    () => [
      // {
      //   name: 'Program',
      //   id: 'program',
      //   route: generateRoute(ROUTES.ORGANIZATION_TEAM_PLAYBOOK, { organizationId, teamId }),
      // },
      {
        name: 'Users',
        id: 'users',
        route: generateRoute(ROUTES.ORGANIZATION_TEAM_USERS, { organizationId, teamId }),
      },
    ],
    [organizationId, teamId]
  )
  const activeTab = useMemo(() => tabs[0].id, [tabs])
  const [teams] = useTeamsForOrganization(organization?.id)

  const { setMobileSideContent } = useStore(state => ({
    setMobileSideContent: state.setMobileSideContent,
  }))

  const handleFetchFullContent = useCallback(async () => {
    setIsFullContentLoading(true)
    const content = await fetchAllContent()
    setFullContent(content)

    setIsFullContentLoading(false)
  }, [])

  const handleFetchTeamContent = useCallback(async () => {
    if ((teamId, team?.availableTopics && fullContent)) {
      setIsTeamTopicsLoading(true)

      const teamAvailableTopics = team?.availableTopics
      const topics = fullContent.topics
      const missions = fullContent.missions
      const series = fullContent.series

      const fullTeamContent = teamAvailableTopics?.map(teamAvailableTopic => {
        const fullContentTopic = topics.find(t => t.id === teamAvailableTopic.id) || {}

        const fullContentTopicMissions = fullContentTopic?.missionsAndSeries || []

        return {
          ...teamAvailableTopic,
          ...fullContentTopic,
          missionsAndSeries: fullContentTopicMissions.map(mission => {
            const availableTopicMission = teamAvailableTopic?.missionsAndSeries?.find(
              tam => tam.id === mission.id
            )

            const isMissionAvailableInTeamTopic = !!availableTopicMission

            let mappedMission = mapFirebaseMissionAndSerie(
              isMissionAvailableInTeamTopic ? availableTopicMission : mission,
              missions,
              series
            )

            if (!isMissionAvailableInTeamTopic) {
              mappedMission.isAssigned = false
            }

            return mappedMission
          }),
        }
      })

      setTeamTopics(fullTeamContent)
      setIsTeamTopicsLoading(false)
    }
  }, [teamId, team?.availableTopics, fullContent])

  const handleSaveContent = useCallback(
    async topics => {
      await updateTeamTopics(
        teamId,
        users.map(user => user.id),
        topics
      )
      await sleep(1000)
    },
    [teamId, users]
  )

  const handleTabChange = useCallback(
    tab => {
      navigate(tab.route, {
        state: { organization, team },
      })
    },
    [navigate, organization, team]
  )

  const handleOpenEditTeamSlideOver = useCallback(() => {
    setIsEditTeamSlideOverOpen(true)
  }, [])

  const handleCloseEditTeamSlideOver = useCallback(() => {
    setIsEditTeamSlideOverOpen(false)
  }, [])

  const handleOnAddTeamClick = useCallback(() => {
    navigate(generateRoute(ROUTES.ADD_TEAM, { organizationId: organization?.id }), {
      state: { organization },
    })
  }, [navigate, organization])

  const handleOnTeamClick = useCallback(
    team => {
      setMobileSideContent(false)

      navigate(
        generateRoute(
          ROUTES.ORGANIZATION_TEAM,
          {
            organizationId: organization?.id,
            teamId: team?.id,
          },
          {
            state: { organization, team },
          }
        )
      )
    },
    [navigate, organization, setMobileSideContent]
  )

  const downloadCSV = useCallback(
    async e => {
      e.preventDefault()
      setIsCSVDownloading(true)
      setCsvDownloadError(null)

      try {
        const result = await generateTeamQuestionnaireReportFBFunction({ teamId: team.id })
        const csvData = result.data.data
        const blob = new Blob([csvData], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)

        // Create a temporary <a> element to trigger download
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = `${team?.name.replace(/\s+/g, '-').toLowerCase()}-questionnaire.csv`
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      } catch (error) {
        setCsvDownloadError('Error downloading the CSV')
        console.error('Error downloading the CSV:', error)
      } finally {
        setIsCSVDownloading(false)
      }
    },
    [team?.name, team?.id]
  )

  const isLoading =
    isOrganizationLoading ||
    isTeamLoading ||
    isUsersLoading ||
    isFullContentLoading ||
    isTeamTopicsLoading

  const breadcrumbs = Object.values(organization || {})?.length
    ? [
        { type: 'home', navigateTo: ROUTES.ROOT },
        {
          name: organization?.name,
          navigateTo: generateRoute(ROUTES.ORGANIZATION, { organizationId }),
          state: { organization },
        },
        { name: team?.name },
      ]
    : []

  useEffect(() => {
    handleFetchFullContent()
  }, [handleFetchFullContent])

  useEffect(() => {
    handleFetchTeamContent()
  }, [handleFetchTeamContent])

  return (
    <OPageWrapper
      sideContent={
        <OOrganizationTeamsList
          activeTeamId={teamId}
          teams={teams}
          onAddTeamClick={handleOnAddTeamClick}
          onTeamClick={handleOnTeamClick}
          readOnly={!isSuperAdmin}
        />
      }
      shouldExpandMobileSideContent={false}
      isLoading={isLoading}
      breadcrumbs={breadcrumbs}
    >
      <div>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <div className="flex flex-col">
              <h1 className="text-2xl font-semibold leading-6 text-gray-900">{team?.name}</h1>

              {!!users?.length && (
                <div className="flex flex-col">
                  <div
                    className={classnames('mt-1 text-sm flex flex-row items-center space-x-2', {
                      'cursor-pointer': !isCSVDownloading,
                      'opacity-50 cursor-wait': isCSVDownloading,
                      'text-gray-900 hover:text-accent underline': !csvDownloadError,
                      'text-red-500 hover:underline': !!csvDownloadError,
                    })}
                    onClick={downloadCSV}
                  >
                    <span>Download Questionnaire CSV</span>
                    {!!csvDownloadError && (
                      <>
                        <ExclamationCircleIcon className="h-5 w-5 -mt-0.5 ml-1 text-red-500" />
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="inline-flex mt-4 sm:ml-16 sm:mt-0">
            <AButton onClick={handleOpenEditTeamSlideOver}>Edit group</AButton>
            {isSuperAdmin ? (
              <AButton
                className="ml-2 w-full"
                onClick={() => {
                  navigate(generateRoute(ROUTES.ADD_TEAM_USER, { organizationId, teamId }), {
                    state: { organization, team },
                  })
                }}
              >
                Add user
              </AButton>
            ) : null}
          </div>
        </div>
        <div className="mt-10">
          <Routes>
            <Route
              path={'/'}
              element={
                <OUsersList
                  key={team?.id}
                  users={users}
                  allowedColumns={{ phoneNumber: true }}
                  onItemClick={user => {
                    navigate(
                      generateRoute(ROUTES.TEAM_USER, { organizationId, teamId, userId: user.id }),
                      {
                        state: { organization, team, user },
                      }
                    )
                  }}
                />
              }
            />
          </Routes>
        </div>
      </div>

      <OSlideOver
        slideContent={
          <OEditTeam
            team={team}
            updateTeam={updateTeam}
            onSavingFinished={handleCloseEditTeamSlideOver}
            allowedFields={{ leaderboards: false }}
          />
        }
        isOpen={isEditTeamSlideOverOpen}
        onClose={handleCloseEditTeamSlideOver}
      ></OSlideOver>
    </OPageWrapper>
  )
}

export default POrganizationTeam
