import { initializeAuth, browserLocalPersistence } from 'firebase/auth'
import { getFirestore, collection, doc, getDoc, updateDoc } from 'firebase/firestore'
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions'
// import firebase from 'firebase/compat/app'
import { initializeApp } from 'firebase/app'
import { DateTime } from 'luxon'

let isFirebaseInitialized = false
let firebaseApp
let firestoreDb
let functions
let auth
let localTimezone = DateTime.local().zoneName
let doesUserExistFBFunction
let doesAdminUserExistsFbFunction
let doesOrganizationExistsFbFunction
let doesTeamExistsFbFunction
let retrieveChatCredentials
let retrieveTeamLeaderboards
let createUserFunction
let updateUserFunction
let archiveUserFunction
let archiveTeamFunction
let updateOrganizationsFunction
let usersCollectionRef
let adminUsersCollectionRef
let organizationsCollectionRef
let teamsCollectionRef
let userQuestionnairesCollectionRef
let userNotesCollectionRef

const COLLECTIONS = {
  users: 'users',
  adminUsers: 'adminUsers',
  organizations: 'organizations',
  teams: 'teams',
  acl: 'acl',
  userQuestionnaires: '/userQuestionnaires',
  userNotes: '/userNotes',
}

const initFirebase = ({
  config,
  timezoneAdapter,
  persistenceAdapter = browserLocalPersistence,
}) => {
  if (!isFirebaseInitialized) {
    firebaseApp = initializeApp(config.firebase)
    firestoreDb = getFirestore(firebaseApp)
    functions = getFunctions(firebaseApp)
    auth = initializeAuth(firebaseApp, { persistence: persistenceAdapter })

    if (config.connectFirebaseEmulators) {
      connectFunctionsEmulator(functions, 'localhost', 5001)
    }

    if (timezoneAdapter) {
      localTimezone = timezoneAdapter
    }
    /**
     * Initialize fucntions
     */
    doesUserExistFBFunction = httpsCallable(functions, 'doesUserExist')
    doesAdminUserExistsFbFunction = httpsCallable(functions, 'doesAdminUserExist')
    doesOrganizationExistsFbFunction = httpsCallable(functions, 'doesOrganizationExists')
    doesTeamExistsFbFunction = httpsCallable(functions, 'doesTeamExists')
    retrieveChatCredentials = httpsCallable(functions, 'retrieveChatCredentials')
    retrieveTeamLeaderboards = httpsCallable(functions, 'retrieveTeamLeaderboards')

    createUserFunction = httpsCallable(functions, 'createUser')
    updateUserFunction = httpsCallable(functions, 'updateUser')
    archiveUserFunction = httpsCallable(functions, 'archiveUser')
    archiveTeamFunction = httpsCallable(functions, 'archiveTeam')
    updateOrganizationsFunction = httpsCallable(functions, 'updateOrganizations')
    /**
     * Initialize collections
     */
    usersCollectionRef = collection(firestoreDb, COLLECTIONS.users)
    organizationsCollectionRef = collection(firestoreDb, COLLECTIONS.organizations)
    teamsCollectionRef = collection(firestoreDb, COLLECTIONS.teams)
    userQuestionnairesCollectionRef = collection(firestoreDb, COLLECTIONS.userQuestionnaires)
    userNotesCollectionRef = collection(firestoreDb, COLLECTIONS.userNotes)
    adminUsersCollectionRef = collection(firestoreDb, COLLECTIONS.adminUsers)

    isFirebaseInitialized = true
  }

  return { firebaseApp, firestoreDb, functions, auth }
}

const updateAdminUserMetadata = async uid => {
  if (!uid) {
    throw new Error('No uid available')
  }

  const adminUserRef = doc(firestoreDb, COLLECTIONS.adminUsers, uid)
  const adminUserSnap = await getDoc(adminUserRef)

  if (adminUserSnap.exists()) {
    const localTimezone = DateTime.local().zoneName

    const { didJoin, timezone } = adminUserSnap.data()

    let dataToUpdate = {}

    if (!didJoin) {
      dataToUpdate.didJoin = true
    }

    if (localTimezone !== timezone) {
      dataToUpdate.timezone = localTimezone
    }

    const hasUserDataChanged = Object.keys(dataToUpdate).length

    if (hasUserDataChanged) {
      await updateDoc(adminUserRef, dataToUpdate)
    }
  }
}

export {
  firebaseApp,
  firestoreDb,
  functions,
  auth,
  COLLECTIONS,
  initFirebase,
  doesUserExistFBFunction,
  doesAdminUserExistsFbFunction,
  doesOrganizationExistsFbFunction,
  doesTeamExistsFbFunction,
  retrieveChatCredentials,
  retrieveTeamLeaderboards,
  createUserFunction,
  updateUserFunction,
  archiveUserFunction,
  archiveTeamFunction,
  updateOrganizationsFunction,
  usersCollectionRef,
  organizationsCollectionRef,
  teamsCollectionRef,
  updateAdminUserMetadata,
  userQuestionnairesCollectionRef,
  userNotesCollectionRef,
  adminUsersCollectionRef,
}
