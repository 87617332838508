const CONFIG = {
  firebase: {
    apiKey: 'AIzaSyCtSj1UfnkN4fv_bMbBRZuAQzQaX-zw4ro',
    authDomain: 'poppy-6b07f.firebaseapp.com',
    projectId: 'poppy-6b07f',
    storageBucket: 'poppy-6b07f.appspot.com',
    messagingSenderId: '750264804875',
    appId: '1:750264804875:web:25558354953a6add5f490e',
  },
  connectFirebaseEmulators: false,
  edwinCloudApi: 'https://us-central1-edwincloud-5a00d.cloudfunctions.net/api/v1',
  domain: 'https://admin.beautyedit.app',
}

export default CONFIG
