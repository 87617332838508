import React from 'react'
import { AFormTextArea } from '../atoms'

export const MFormMarkdownEditor = React.forwardRef(
  ({ label, id, name, disabled, className, errorMessage, ...props }, ref) => {
    return (
      <AFormTextArea
        ref={ref}
        id={id}
        name={name}
        disabled={disabled}
        className={className}
        {...props}
      />
    )
  }
)
