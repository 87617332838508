import { useCallback, useMemo, useState, useEffect } from 'react'

import { DateTime } from 'luxon'
import ReactFlow, { Handle, Position } from 'reactflow'
import { MCard } from '../molecules/MCard'

import { query, orderBy } from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'

import { SquaresPlusIcon, FolderOpenIcon, Squares2X2Icon } from '@heroicons/react/24/outline'
import 'reactflow/dist/style.css'

import { userQuestionnairesCollectionRef, fetchAllContent } from '@edwin/sdk-admin'

function GroupNode({ data }) {
  return (
    <div className="px-4 py-4 shadow-md rounded-md bg-white border-2 border-emerald-300 min-w-max">
      <div className="flex">
        <div className="rounded-full w-10 h-10 flex justify-center items-center bg-emerald-500 shrink-0">
          <FolderOpenIcon className="w-5 h-5 text-gray-50" />
        </div>
        <div className="ml-3 flex flex-col items-start justify-start">
          <div className="text-gray-600 text-xs uppercase text-left">Question group</div>
          <div className="text-lg text-left">{data.label}</div>
        </div>
      </div>

      <Handle
        type="source"
        position={Position.Bottom}
        className="w-2 !bg-emerald-300 !border-0 !z-0 !rounded-none"
      />
    </div>
  )
}

function NestedGroupNode({ data }) {
  return (
    <div className="px-4 py-4 shadow-md rounded-md bg-white border-2 border-emerald-400 min-w-max">
      <div className="flex">
        <div className="rounded-full w-10 h-10 flex justify-center items-center bg-emerald-600">
          <FolderOpenIcon className="w-5 h-5 text-gray-50" />
        </div>
        <div className="ml-3 flex flex-col items-start justify-start">
          <div className="text-gray-600 text-xs uppercase">Nested group</div>
          <div className="text-lg">
            {data.groupName} &rarr; {data.label}
          </div>
        </div>
      </div>

      <Handle
        type="target"
        position={Position.Left}
        className="w-2 h-2 !bg-emerald-400 rotate-90 !border-0 !z-0 !rounded-none"
      />
      <Handle
        type="source"
        position={Position.Bottom}
        className="w-2 h-2 !bg-emerald-400 !border-0 !z-0 !rounded-none"
      />
    </div>
  )
}

function QuestionNode({ data }) {
  return (
    <div className="px-4 py-4 shadow-md rounded-md bg-white border-2 border-indigo-200 min-w-max">
      <div className="flex">
        <div className={`rounded-full w-10 h-10 flex justify-center items-center bg-accent`}>
          <Squares2X2Icon className="w-5 h-5 text-gray-50" />
        </div>
        <div className="ml-3 flex flex-col items-center justify-center">
          {/* <div className="text-gray-600 text-xs uppercase">Question</div> */}
          <div className="text-lg font-bold">{data.label}</div>
        </div>
      </div>

      <Handle
        type="target"
        position={Position.Left}
        className="w-2 h-2 !bg-accent bg-opacity-10 rotate-90 !border-0 !z-0 !rounded-none"
      />
      <Handle
        type="source"
        position={Position.Bottom}
        className="w-2 h-2 !bg-accent bg-opacity-10 !border-0 !z-0 !rounded-none"
      />
    </div>
  )
}

function AnswerNode({ data }) {
  return (
    <div className="px-4 py-4 bg-violet-700 shadow-md rounded-md  border-2 border-violet-400 min-w-max">
      <div className="flex">
        <div className={`rounded-full w-10 h-10 flex justify-center items-center bg-white `}>
          <SquaresPlusIcon className="w-5 h-5 text-violet-700 " />
        </div>
        <div className="ml-3 flex flex-col items-center justify-center text-gray-50">
          {/* <div className=" text-xs uppercase">Answer</div> */}
          <div className="text-lg font-bold">{data.label}</div>
        </div>
      </div>

      <Handle
        type="target"
        position={Position.Left}
        className="w-2 !bg-violet-400 rotate-90 !border-0 !rounded-none"
      />
      {/* <Handle type="source" position={Position.Bottom} className="w-2 !bg-teal-500" /> */}
    </div>
  )
}

const nodeTypes = {
  groupNode: GroupNode,
  nestedGroup: NestedGroupNode,
  questionNode: QuestionNode,
  answerNode: AnswerNode,
}

export const OMAQuestionnaires = () => {
  const [activeQuestionnaire, setActiveQuestionnaire] = useState()
  const [missions, setMissions] = useState([])
  const [areMissionsLoading, setAreMissionsLoading] = useState(true)

  const userQuestionnairesCollectionQuery = useMemo(() => {
    return query(userQuestionnairesCollectionRef, orderBy('createdOn', 'desc'))
  }, [])
  const [questionnaires] = useCollectionData(userQuestionnairesCollectionQuery, {
    initialValue: [],
  })

  const handleFetchContent = useCallback(async () => {
    setAreMissionsLoading(true)
    const content = await fetchAllContent()
    setMissions(content?.missions || [])
    setAreMissionsLoading(false)
  }, [])

  useEffect(() => {
    const asyncFn = async () => {
      await handleFetchContent()
    }

    asyncFn()
  }, [handleFetchContent])

  const groupedQuestions = useMemo(() => {
    let groups = {}
    if (!activeQuestionnaire) return groups

    Object.values(activeQuestionnaire.questionnaireAnswers).forEach(item => {
      let groupName = item.groupId
      let nestedGroupName

      if (groupName.includes('---')) {
        ;[groupName, nestedGroupName] = groupName.split(' --- ')

        if (!groups[groupName]) {
          groups[groupName] = { items: [], nestedGroups: {} }
        }

        if (!groups[groupName].nestedGroups[nestedGroupName]) {
          groups[groupName].nestedGroups[nestedGroupName] = []
        }

        groups[groupName].nestedGroups[nestedGroupName].push(item)
      } else {
        if (!groups[groupName]) {
          groups[groupName] = { items: [], nestedGroups: {} }
        }

        groups[groupName].items.push(item)
      }
    })

    // Sort items in each group and nested group by priority
    for (let group in groups) {
      groups[group].items.sort((a, b) => a.priority - b.priority)

      for (let nestedGroup in groups[group].nestedGroups) {
        groups[group].nestedGroups[nestedGroup].sort((a, b) => a.priority - b.priority)
      }
    }

    return groups
  }, [activeQuestionnaire])

  const { nodes, edges } = useMemo(
    questionnaire => {
      const nodes = []
      const edges = []

      let positionY = 0
      Object.entries(groupedQuestions).forEach(([groupName, group], groupIndex) => {
        // Add the group node
        const groupNodeId = `group-${groupIndex}`
        nodes.push({
          id: groupNodeId,
          data: { label: `${groupName}` },
          position: { x: 0, y: positionY },
          type: 'groupNode',
          // style: { backgroundColor: '#d9f99d', height: 80 },
        })
        positionY += 150

        group.items.forEach((item, itemIndex) => {
          const itemNodeId = `${groupNodeId}-item-${itemIndex}`

          nodes.push({
            id: itemNodeId,
            data: { label: item.questionLabel, groupName },
            position: { x: 150, y: positionY },
            type: 'questionNode',
          })

          item.answers.forEach((answer, answerIndex) => {
            const answerNodeId = `${itemNodeId}-answer-${answerIndex}`
            nodes.push({
              id: answerNodeId,
              data: { label: `${answer.label}` },
              position: { x: 350, y: positionY + 100 }, // Increase X position here for answers
              type: 'answerNode',
            })
            edges.push({
              id: `e-${itemNodeId}-${answerNodeId}`,
              source: itemNodeId,
              target: answerNodeId,
            })

            positionY += 150 // Increment Y position after processing both question and answer
          })

          edges.push({
            id: `e-${groupNodeId}-${itemNodeId}`,
            source: groupNodeId,
            target: itemNodeId,
          })

          positionY += 100 // Increment Y position for the next question
        })

        Object.entries(group.nestedGroups).forEach(
          ([nestedGroupName, nestedGroup], nestedGroupIndex) => {
            const nestedGroupNodeId = `${groupNodeId}-nestedGroup-${nestedGroupIndex}`
            nodes.push({
              id: nestedGroupNodeId,
              data: { label: `${nestedGroupName}`, groupName },
              position: { x: 150, y: positionY },
              // style: { backgroundColor: '#ecfccb', height: 80 },
              // sourcePosition: 'bottom',
              // targetPosition: 'left',
              type: 'nestedGroup',
            })
            edges.push({
              id: `e-${groupNodeId}-${nestedGroupNodeId}`,
              source: groupNodeId,
              target: nestedGroupNodeId,
              type: 'smoothstep',
            })
            positionY += 100

            nestedGroup.forEach((item, itemIndex) => {
              const itemNodeId = `${nestedGroupNodeId}-item-${itemIndex}`
              nodes.push({
                id: itemNodeId,
                data: { label: item.questionLabel },
                position: { x: 300, y: positionY },
                type: 'questionNode',
              })

              item.answers.forEach((answer, answerIndex) => {
                const answerNodeId = `${itemNodeId}-answer-${answerIndex}`
                nodes.push({
                  id: answerNodeId,
                  data: { label: `${answer.label}` },
                  position: { x: 500, y: positionY + 100 }, // Increase X position here for answers
                  type: 'answerNode',
                })
                edges.push({
                  id: `e-${itemNodeId}-${answerNodeId}`,
                  source: itemNodeId,
                  target: answerNodeId,
                })

                positionY += 100 // Increment Y position after processing both question and answer
              })

              edges.push({
                id: `e-${nestedGroupNodeId}-${itemNodeId}`,
                source: nestedGroupNodeId,
                target: itemNodeId,
              })

              positionY += 100 // Increment Y position for the next question
            })
          }
        )
      })

      return { nodes, edges }
    },
    [groupedQuestions]
  )

  const getMissionNameById = useCallback(
    missionId => {
      return missions.find(({ id }) => id === missionId)?.title
    },
    [missions]
  )

  return (
    <div className="react-web--admin__container h-full">
      <div className="flex flex-1 min-h-full justify-between flex-wrap">
        <div className="w-96 pr-3">
          <ul className="grid grid-cols-1 gap-y-4">
            {questionnaires.map((questionnaire, index) => (
              <li key={index}>
                <MCard
                  title="User assessment"
                  icon={<SquaresPlusIcon />}
                  onClick={() => {
                    setActiveQuestionnaire(questionnaire)
                  }}
                  className="mb-3"
                  isActive={questionnaire.createdOn === activeQuestionnaire?.createdOn}
                  items={[
                    {
                      label: 'Mission',
                      value: getMissionNameById(questionnaire.missionId) || questionnaire.missionId,
                    },
                    {
                      label: 'Submitted on',
                      value: DateTime.fromISO(questionnaire.createdOn).toLocaleString(
                        DateTime.DATETIME_SHORT
                      ),
                    },
                  ]}
                />
              </li>
            ))}
          </ul>
        </div>
        <div className="flex-1 ml-4 bg-gray-100 rounded-lg flex-auto">
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            fitView
            minZoom={0.4}
            style={{ height: '100%' }}
          />
        </div>
      </div>
    </div>
  )
}
