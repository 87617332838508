import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import { useOrganizations } from '@services/organizations'
import useStore from '@store/useStore'

import ROUTES, { generateRoute } from '@const/Routes'

import { BuildingOfficeIcon } from '@heroicons/react/24/outline'
import { AButton, MCard } from '@edwin/react-web-admin'
import OPageWrapper from '@organisms/OPageWrapper'

const POrganizations = () => {
  const isSuperAdmin = useStore(state => state.isSuperAdmin)
  const navigate = useNavigate()
  const [organizations, isOrganizationsLoading] = useOrganizations()

  const breadcrumbs = [{ type: 'home', navigateTo: ROUTES.ROOT }]

  return (
    <OPageWrapper
      breadcrumbs={breadcrumbs}
      buttonElement={
        isSuperAdmin ? (
          <AButton onClick={() => navigate(generateRoute(ROUTES.ADD_ORGANIZATION))}>
            Add new organization
          </AButton>
        ) : null
      }
      isLoading={isOrganizationsLoading}
    >
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {organizations?.map(org => (
          <li key={org.id} className="col-span-1">
            <Link
              to={generateRoute(ROUTES.ORGANIZATION, { organizationId: org.id })}
              state={{ organization: org }}
            >
              <MCard
                title={org.name}
                subtitle={org.createdAt ? `Added ${format(org.createdAt, 'MMMM yyyy')}` : null}
                icon={<BuildingOfficeIcon />}
                iconBg="bg-organization-bg"
                items={[
                  {
                    label: 'Groups',
                    value: org.teams.length,
                  },
                  {
                    label: 'Active Users',
                    value: org.users.length,
                  },
                  {
                    label: 'Pending Invitations',
                    value: org.invitations,
                  },
                ]}
              />
            </Link>
          </li>
        ))}
      </ul>
    </OPageWrapper>
  )
}

export default POrganizations
