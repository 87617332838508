import { useCallback, useMemo, useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import { orderBy, query, where } from 'firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useForm, Controller } from 'react-hook-form'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { userNotesCollectionRef, updateUser } from '@edwin/sdk-admin'
import ReactMarkdown from 'react-markdown'
import { AButton } from '../atoms/AButton'

import { MCard, MFormInput, MFormMarkdownEditor } from '../molecules'
import { OLoader, OSlideOver } from '../organisms'
import { PencilSquareIcon } from '@heroicons/react/24/outline'

import { useAdminUsers, addUserNote, editUserNote } from '@edwin/sdk-admin'

import { set } from 'lodash'

const EditUserNote = ({ note, title, onSubmit = () => {}, saveLabel = 'Save' }) => {
  const { control, handleSubmit, register, reset } = useForm({
    defaultValues: {
      note: note?.note || '',
    },
  })
  const [isSaving, setIsSaving] = useState(false)

  const onHandleSubmit = useCallback(
    async data => {
      setIsSaving(true)

      try {
        await onSubmit(data)
      } catch (err) {
        console.warn(err)
      } finally {
        setIsSaving(false)
      }
    },
    [onSubmit]
  )

  return (
    <div>
      {!!title?.length && <div className="mb-5">{title}</div>}
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <Controller
          name="note"
          control={control}
          className="w-full"
          render={({ field }) => (
            <MFormMarkdownEditor
              className="h-48"
              {...field}
              {...register('note', {
                required: {
                  value: true,
                  message: 'This field is required',
                },
              })}
            />
          )}
        />
        <div className="flex flex-row items-end justify-end mt-4">
          <div className="mr-2">
            <AButton type="submit" isLoading={isSaving}>
              {saveLabel}
            </AButton>
          </div>
        </div>
      </form>
    </div>
  )
}

export const OUserNotes = ({ className, user, adminId }) => {
  const [isAddNoteSlideOpen, setIsAddNoteSlideOpen] = useState(false)
  const [isEditNoteSlideOpen, setIsEditNoteSlideOpen] = useState(false)
  const [editedNote, setEditedNote] = useState()
  const [missions, setMissions] = useState([])
  const [adminUsers, areAdminUsersLoading] = useAdminUsers()
  const [isNoteSaving, setIsNoteSaving] = useState(false)

  const userNotesCollectionQuery = useMemo(() => {
    return user?.id
      ? query(userNotesCollectionRef, where('userId', '==', user.id), orderBy('createdOn', 'desc'))
      : undefined
  }, [user?.id])

  const [notesRaw, areNotesLoading] = useCollectionData(userNotesCollectionQuery, {
    initialValue: [],
  })
  const isLoading = useMemo(() => {
    return areNotesLoading || areAdminUsersLoading || isNoteSaving
  }, [areNotesLoading, areAdminUsersLoading, isNoteSaving])

  const getAdminUserById = useCallback(
    adminUserId => {
      if (!adminUsers?.length) return null

      return adminUsers.find(adminUser => adminUser.id === adminUserId) || null
    },
    [adminUsers]
  )

  const notes = useMemo(() => {
    if (isLoading) return null

    return notesRaw.map(note => {
      const createdBy = getAdminUserById(note.updatedBy || note.createdBy)

      return { ...note, createdOn: DateTime.fromMillis(note.createdOn.seconds * 1000), createdBy }
    })
  }, [notesRaw, adminUsers, getAdminUserById, isLoading])

  const handleSaveEditedNote = useCallback(
    async data => {
      console.log({ editedNote })
      await editUserNote({ id: editedNote?.id, userId: user?.id, note: data.note })

      setIsEditNoteSlideOpen(false)
      setEditedNote()

      await refreshContent()
    },
    [user?.id, editedNote]
  )

  const handleSaveAddedNote = useCallback(
    async data => {
      await addUserNote({ userId: user?.id, note: data.note })

      setIsAddNoteSlideOpen(false)

      await refreshContent()
    },
    [user?.id]
  )

  const refreshContent = useCallback(async () => {}, [])

  if (isLoading) {
    return (
      <div className="react-web--admin__container" style={{ height: '100%' }}>
        <div className="flex items-center h-full">
          <OLoader className="h-full" />
        </div>
      </div>
    )
  }

  return (
    <div className="react-web--admin__container">
      <div className="flex flex-row justify-end mb-8 w-full">
        <div>
          <AButton onClick={() => setIsAddNoteSlideOpen(true)} className="mt-2 mr-6 sm:mt-0">
            Add new note
          </AButton>
        </div>
      </div>

      <div className="flex flex-col min-h-full items-center flex-wrap">
        <div className="w-full max-w-3xl px-3">
          <ul className="grid grid-cols-1 gap-y-4 gap-x-4 justify-center">
            {notes.map((note, index) => (
              <li key={index} className="md:mb-0 mb-3">
                <MCard
                  title="Note"
                  icon={<PencilSquareIcon />}
                  className="mb-3"
                  isActive={note.id === editedNote?.id}
                  ctaElement={
                    <div>
                      <AButton
                        secondary
                        onClick={() => {
                          setEditedNote(note)
                          setIsEditNoteSlideOpen(true)
                        }}
                      >
                        Edit Note
                      </AButton>
                    </div>
                  }
                  items={[
                    {
                      label: 'Created on',
                      value: note.createdOn.toLocaleString(DateTime.DATETIME_SHORT),
                    },
                    {
                      label: 'Created by',
                      value: note.createdBy
                        ? `${note.createdBy.firstName} ${note.createdBy.lastName}`
                        : 'Unknown',
                    },
                    {
                      value: <ReactMarkdown>{note.note}</ReactMarkdown>,
                    },
                  ]}
                />
              </li>
            ))}
          </ul>
        </div>
        <OSlideOver
          slideContent={
            <EditUserNote note={editedNote} saveLabel="Edit note" onSubmit={handleSaveEditedNote} />
          }
          isOpen={isEditNoteSlideOpen}
          onClose={() => {
            setIsEditNoteSlideOpen(false)
            setEditedNote()
          }}
        ></OSlideOver>

        <OSlideOver
          slideContent={
            <EditUserNote note={editedNote} saveLabel="Add note" onSubmit={handleSaveAddedNote} />
          }
          isOpen={isAddNoteSlideOpen}
          onClose={() => setIsAddNoteSlideOpen(false)}
        ></OSlideOver>
      </div>
    </div>
  )
}

OUserNotes.propTypes = {
  className: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    notes: PropTypes.string,
  }).isRequired,
}
