import React, { useMemo } from 'react'
import { useLocation, useNavigate, Route, Routes, Navigate } from 'react-router-dom'

import { RectangleGroupIcon, SquaresPlusIcon, TableCellsIcon } from '@heroicons/react/20/solid'

import {
  MTabs,
  OMAQuestionnaires,
  OMAQuestionnairesGrid,
  OMAQuestionnairesTable,
} from '@edwin/react-web-admin'

import OPageWrapper from '@organisms/OPageWrapper'

import ROUTES from '@const/Routes'

const TABS = [
  { id: 'table', name: 'Table', icon: TableCellsIcon },
  { id: 'grid', name: 'Grid', icon: RectangleGroupIcon },
  { id: 'diagram', name: 'Diagram', icon: SquaresPlusIcon },
]

const PAssessments = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const activeTab = useMemo(() => {
    const pathParts = location.pathname.split('/')
    const lastPart = pathParts[pathParts.length - 1]
    if (TABS.find(tab => tab.id === lastPart)) {
      return lastPart
    } else {
      return 'diagram'
    }
  }, [location?.pathname])

  const breadCrumbs = useMemo(() => {
    return [{ type: 'home', navigateTo: ROUTES.ASSESSMENTS }]
  }, [])

  return (
    <OPageWrapper breadcrumbs={breadCrumbs} isLoading={false}>
      <MTabs
        tabs={TABS}
        activeTab={activeTab}
        onTabChange={newTab => navigate(newTab.id)}
        className="mb-12"
      />

      <Routes>
        <Route path="diagram" element={<OMAQuestionnaires />} />
        <Route path="grid" element={<OMAQuestionnairesGrid />} />
        <Route path="table" element={<OMAQuestionnairesTable />} />
        <Route path="*" element={<Navigate to="table" replace />} />
      </Routes>
    </OPageWrapper>
  )
}

export default PAssessments
