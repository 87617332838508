const ROUTES = {
  ROOT: '/',
  SIGN_IN_WITH_EMAIL_LINK: '/sign-in-with-email-link',
  USERS: '/users',
  ADD_USER: '/users/add',
  USER: '/users/:userId',
  ORGANIZATION: '/organizations/:organizationId',
  ADD_ORGANIZATION: '/organizations/add',
  ADD_TEAM: '/organizations/:organizationId/group/add',
  ORGANIZATION_TEAM: '/organizations/:organizationId/group/:teamId',
  ORGANIZATION_TEAM_PLAYBOOK: '/organizations/:organizationId/group/:teamId/program',
  ORGANIZATION_TEAM_USERS: '/organizations/:organizationId/group/:teamId/users',
  TEAM_USER: '/organizations/:organizationId/group/:teamId/users/:userId',
  ADD_TEAM_USER: '/organizations/:organizationId/group/:teamId/users/add',
  ADD_ADMIN_USER: '/admin-users/add',
  ADMIN_USER: '/admin-users/:adminUserId',
  ADMIN_USERS: '/admin-users',
  CHAT: '/chat',
  ASSESSMENTS: '/assessments',
}

export const generateRoute = (routeName = '', params = {}) => {
  const { userId, adminUserId, organizationId, teamId } = params

  return routeName
    .replace(':organizationId', organizationId)
    .replace(':teamId', teamId)
    .replace(':userId', userId)
    .replace(':adminUserId', adminUserId)
}

export default ROUTES
