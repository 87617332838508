import { DateTime } from 'luxon'
import { getAxiosInstance } from './axios.js'
import { parseGoal, parseGoalInstance } from '../utils/goals.js'
import { generateId } from '../utils/helpers.js'

export const fetchUserGoals = async userId => {
  const userGoalsResponse = await getAxiosInstance().get(`/admin/user-goals/${userId}`)
  const userGoalsData = userGoalsResponse?.data

  const { goals, goalInstances } = userGoalsData

  const parsedGoals = goals.map(goal => parseGoal(goal))
  const parsedGoalInstances = goalInstances.map(goalInstance => parseGoalInstance(goalInstance))

  const goalInstancesById = parsedGoalInstances.reduce((acc, curr) => {
    let currentInstanceArray = acc[curr.goalId] ? [...acc[curr.goalId], curr] : [curr]

    return {
      ...acc,
      [curr.goalId]: currentInstanceArray.sort(
        (a, b) => new Date(a.startsOn) - new Date(a.startsOn)
      ),
    }
  }, {})

  const goalsParsed = parsedGoals.map(goal => {
    return parseGoal(goal)
  })

  const goalInstancesFinal = goalInstances
    .filter(goalInstance => {
      const relatedGoal = goals.find(goal => goal.id === goalInstance.goalId)

      if (!relatedGoal) {
        return false
      }

      const isInstanceGoalMarkedAsDeleted = goals.find(
        goal => goal.id === goalInstance.goalId
      )?.isDeleted

      if (isInstanceGoalMarkedAsDeleted) {
        return false
      }

      return !goalInstance.isDeleted
    })
    .map(goalInstance => parseGoalInstance(goalInstance))
    .sort((a, b) => new Date(a.startsOnDate) - new Date(b.startsOnDate))

  const goalsFinal = goalsParsed
    .filter(goal => {
      const instances = goalInstances.filter(instance => instance.goalId === goal.id)
      const isMarkedAsDeleted = goal.isDeleted
      const isEveryInstanceMarkedAsDeleted = instances?.length
        ? instances
            .filter(instance => instance.goalId === goal.id)
            .every(instance => instance.isDeleted)
        : false

      if (isEveryInstanceMarkedAsDeleted) {
        return false
      }

      return !isMarkedAsDeleted
    })
    .sort((a, b) => new Date(a.instancesStartOn) - new Date(b.instancesStartOn))

  return {
    goals: goalsFinal,
    goalInstances: goalInstancesFinal,
  }
}

export const generateReminderPayload = reminder => {
  if (!reminder) return null

  const { whenToSend, title, subtitle, body } = reminder

  return {
    id: generateId(),
    whenToSend,
    meta: { title, subtitle, body },
  }
}

export const createGoalAndInstancesPayload = inputPayload => {
  const { description, endDate, typeMeta, prompt, startDate, title, remindersData } = inputPayload

  const goalId = generateId()

  const goal = {
    templateId: goalId,
    id: goalId,
    title,
    prompt,
    description,
    type: 'boolean',
    typeMeta,
    isRepeating: true,
    allowComments: false,
    timeMeta: {
      startsOn: 0,
      daysOfAvailablity: 7,
      cadenceInDays: 1,
    },
    allowMultipleTrackingsPerInstance: false,
    createdBy: {
      type: 'provider',
    },
    createdOn: DateTime.now().toISO(),
    updatedOn: DateTime.now().toISO(),
  }

  const createInstances = () => {
    const instances = []
    let currentDate = startDate

    while (currentDate <= endDate) {
      const startsOn = currentDate.startOf('day').toISO()
      const endsOn = currentDate.endOf('day').toISO()

      const instance = {
        id: generateId(),
        startsOn,
        endsOn,
      }

      if (remindersData) {
        const whenToSend = currentDate.startOf('day').plus({ hours: 18 }).toISO()

        instance.reminders = [generateReminderPayload({ ...remindersData, whenToSend })]
      }

      instances.push(instance)

      currentDate = currentDate.plus({ days: 1 })
    }

    return instances
  }

  return [
    {
      goal,
      instances: createInstances(),
    },
  ]
}

export const updateAllGoalReminders = async (goalId, reminderPayload) => {
  const { title, subtitle, body, ...data } = reminderPayload

  const reminders = await getAxiosInstance().get(`/admin/goals-reminders/${goalId}`)

  const payload = reminders.data.map(reminder => {
    const dataToUpdate = { ...data }

    if (title || subtitle || body) {
      dataToUpdate.meta = {}
    }

    if (title) dataToUpdate.meta.title = title
    if (subtitle) dataToUpdate.meta.subtitle = subtitle
    if (body) dataToUpdate.meta.body = body

    return {
      id: reminder.id,
      ...dataToUpdate,
    }
  })

  return getAxiosInstance().patch('/admin/goals-reminders', payload)
}

export const createEditGoalPayload = (goalId, inputPayload) => {
  const { description, typeMeta, prompt, title } = inputPayload

  const goal = {
    id: goalId,
    title,
    prompt,
    description,
    updatedOn: DateTime.now().toISO(),
  }

  if (typeMeta) {
    goal.typeMeta = typeMeta
  }

  return [goal]
}

/**
 * Creates a goal with associated instances and saves them to the server.
 *
 * @function
 * @async
 * @param {Object[]} payload - An array of objects containing a goal and its instances.
 * @param {Object} payload[].goal - The goal object.
 * @param {Object[]} payload[].instances - An array of instance objects associated with the goal.
 * @param {string} userId - The user ID associated with the goal and instances.
 * @returns {Promise<AxiosResponse>} A promise that resolves to the axios response when the POST request is complete.
 */
export const createUserGoalWithInstances = async (payload = {}, userId) => {
  if (!userId) throw new Error('No userId specified')

  const everyPayloadItemHasGoalAndInstances = payload.every(
    item => item.goal?.id && item.instances?.length
  )

  if (!everyPayloadItemHasGoalAndInstances) throw new Error('Invalid goal and instances payload')

  return getAxiosInstance().post('/admin/user-goals', { data: payload, userId })
}

export const updateGoals = async (payload = []) => {
  if (!payload?.every(goal => goal.id)) {
    throw new Error('No goal id provided')
  }

  return getAxiosInstance().patch(`/admin/goals`, payload)
}

export const deleteGoal = async goalId => {
  if (!goalId) {
    throw new Error('No goal id provided')
  }

  return getAxiosInstance().delete(`/admin/goal/${goalId}`)
}
