import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export const AFormCheckboxItem = ({ id, name, checked, onChange, reversed, disabled, className }) => {
  return (
    <div className="react-web--admin__container">
      <label
        key={id}
        htmlFor={id}
        className={classnames('relative flex items-start', {
          [className]: true,
          'flex-row-reverse': reversed,
        })}
      >
        <div className="min-w-0 flex-1 text-sm">
          <div className="text-sm text-gray-500 select-none">{name}</div>
        </div>
        <div
          className={classnames('flex items-center justify-center h-5 w-5', {
            'ml-3': !reversed,
            'mr-3': reversed,
          })}
        >
          <input
            id={id}
            name={name}
            checked={checked}
            onChange={onChange}
            type="checkbox"
            disabled={disabled}
            className="h-4 w-4 rounded border border-gray-300 text-accent focus:ring-accent"
          />
        </div>
      </label>
    </div>
  )
}

AFormCheckboxItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  reversed: PropTypes.bool,
  className: PropTypes.string,
}
