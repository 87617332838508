import { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { signOut } from 'firebase/auth'

import { auth } from '@services/firebase'

const useAuthClaims = () => {
  const [authUser, isAuthUserLoading] = useAuthState(auth)
  const [claims, setClaims] = useState()
  const [error, setError] = useState(null)

  useEffect(() => {
    if (authUser) {
      authUser
        .getIdTokenResult(true)
        .then(tokenResult => {
          const claims = tokenResult?.claims || {}

          const { adminAccessToken } = claims

          if (adminAccessToken) {
            setClaims(tokenResult.claims)
            setError(null)
          } else {
            setError('You are not authorized to access this page')
            signOut(auth)
          }
        })
        .catch(setError)
    } else {
      setError(null)
    }
  }, [authUser])

  return [claims, isAuthUserLoading, error]
}

export default useAuthClaims
