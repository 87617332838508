import React, { useState, useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { AButton, AFormWrapper, MFormInput } from '@edwin/react-web-admin'
import OPageWrapper from '@organisms/OPageWrapper'

import { addOrganization } from '@services/organizations'
import ROUTES, { generateRoute } from '@const/Routes'

const PAddOrganization = () => {
  const navigate = useNavigate()
  const [organizationData, setOrganizationData] = useState()
  const [isSaving, setIsSaving] = useState(false)
  const [allDone, setAllDone] = useState(false)
  const [error, setError] = useState()
  const [showConfirmation, setShowConfirmation] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    defaultValues: organizationData,
  })

  const breadcrumbs = [{ type: 'home', navigateTo: ROUTES.ROOT }, { name: 'Add Organization' }]

  const [nameField] = watch(['name'])

  useEffect(() => {
    setShowConfirmation(false)
  }, [nameField])

  useEffect(() => {
    if (allDone) {
      setTimeout(() => {
        navigate(generateRoute(ROUTES.ROOT))
      }, 2000)
    }
  }, [allDone, navigate, reset])

  const handleOrganizationFormSubmit = data => {
    setError(null)
    setOrganizationData(data)
    setShowConfirmation(true)
  }

  const saveOrganizationData = useCallback(async () => {
    setIsSaving(true)

    try {
      await addOrganization(organizationData)
      setIsSaving(false)
      setAllDone(true)
    } catch (err) {
      console.log(err)
      setError(err?.message)
      setIsSaving(false)
    }
  }, [organizationData])

  return (
    <OPageWrapper breadcrumbs={breadcrumbs}>
      <AFormWrapper title="Add Organization">
        <form onSubmit={handleSubmit(handleOrganizationFormSubmit)} method="POST">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <MFormInput
                  id="name"
                  label="Name"
                  name="name"
                  errorMessage={errors?.name?.message}
                  disabled={isSaving || allDone}
                  {...register('name', {
                    required: {
                      value: true,
                      message: 'This field is required',
                    },
                  })}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <div className="flex flex-col items-end">
              {allDone && !error ? (
                <div className="rounded-md px-3 py-2 text-center text-sm font-semibold text-white shadow-sm bg-accent">
                  All done{' '}
                  <span role="img" aria-label="confetti">
                    🎉
                  </span>
                </div>
              ) : (
                <>
                  {showConfirmation ? (
                    <div className="flex items-center space-x-4">
                      <p className="whitespace-nowrap text-sm text-right leading-5 text-gray-600">
                        Are you sure?
                      </p>
                      <AButton
                        secondary
                        onClick={() => {
                          setShowConfirmation(false)
                        }}
                      >
                        Cancel
                      </AButton>
                      <AButton
                        isLoading={isSaving}
                        className="whitespace-nowrap"
                        onClick={saveOrganizationData}
                      >
                        Yes, add new organization
                      </AButton>
                    </div>
                  ) : (
                    <div>
                      <AButton type="submit">Save</AButton>
                    </div>
                  )}
                  {!!error && (
                    <span className="pt-1 text-sm leading-5 font-medium rounded-md text-red-500">
                      {error}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        </form>
      </AFormWrapper>
    </OPageWrapper>
  )
}

export default PAddOrganization
