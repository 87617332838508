import React, { useCallback, useMemo } from 'react'
import { useParams, useLocation, useNavigate, Route, Routes, Navigate } from 'react-router-dom'

import { useUser } from '@services/users'

import {
  UserIcon,
  RectangleGroupIcon,
  CalendarDaysIcon,
  QueueListIcon,
  CursorArrowRaysIcon,
  SquaresPlusIcon,
} from '@heroicons/react/20/solid'

import {
  OUserCalendar,
  MTabs,
  OUserDetailsInfo,
  OAssignContentByUser,
  OUserTimeline,
  OProviderActivity,
  OQuestionnaires,
} from '@edwin/react-web-admin'

import OPageWrapper from '@organisms/OPageWrapper'
import ROUTES, { generateRoute } from '@const/Routes'

const TABS = [
  { id: 'info', name: 'User Details', icon: UserIcon },
  { id: 'program', name: 'Program', icon: RectangleGroupIcon },
  { id: 'calendar', name: 'Calendar', icon: CalendarDaysIcon },
  { id: 'timeline', name: 'Timeline', icon: QueueListIcon },
  { id: 'providers-activity', name: 'Providers Activity', icon: CursorArrowRaysIcon },
  { id: 'questionnaires', name: 'Questionnaires', icon: SquaresPlusIcon },
]

const PUser = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const { userId } = params
  const [user = location?.state?.user, isUserLoading] = useUser(userId)
  const isInOrganizationRoute = useMemo(
    () => location?.pathname?.includes('organizations'),
    [location?.pathname]
  )

  const activeTab = useMemo(() => {
    const pathParts = location.pathname.split('/')
    const lastPart = pathParts[pathParts.length - 1]
    if (TABS.find(tab => tab.id === lastPart)) {
      return lastPart
    } else {
      return 'info'
    }
  }, [location?.pathname])

  /**
   * useMemo hook to get the organization and team data from the user and location state.
   * If the user has an organization and team, they will be used.
   * Otherwise, the organization and team data will be taken from the location state.
   * If there is no location state, empty objects will be returned.
   */
  const { organization, team } = useMemo(() => {
    const organization = user?.organization || location?.state?.organization || {}
    const team = user?.team || location?.state?.team || {}

    return { organization, team }
  }, [location?.state, user])

  /**
   * useMemo hook to generate breadcrumbs based on user, organization and team data.
   * If the current location path includes 'organizations', the breadcrumbs will include the organization and team names.
   * Otherwise, the breadcrumbs will only include the user's full name.
   */
  const breadCrumbs = useMemo(() => {
    if (isInOrganizationRoute) {
      return [
        { type: 'home', navigateTo: ROUTES.ROOT },
        {
          name: organization?.name,
          navigateTo: generateRoute(ROUTES.ORGANIZATION, {
            organizationId: organization?.id,
          }),
          state: { organization },
        },
        {
          name: team?.name,
          navigateTo: generateRoute(ROUTES.ORGANIZATION_TEAM, {
            organizationId: organization?.id,
            teamId: team?.id,
          }),
          state: { organization, team },
        },
        { name: `${user?.firstName} ${user?.lastName}`, state: { organization, team, user } },
      ]
    } else {
      return [
        { type: 'home', navigateTo: ROUTES.USERS },
        { name: `${user?.firstName} ${user?.lastName}` },
      ]
    }
  }, [isInOrganizationRoute, user, organization, team])

  const handleUserDeleted = useCallback(() => {
    if (isInOrganizationRoute) {
      return navigate(
        generateRoute(ROUTES.ORGANIZATION_TEAM, {
          organizationId: organization?.id,
          teamId: team?.id,
        })
      )
    }

    navigate(ROUTES.USERS)
  }, [isInOrganizationRoute, navigate, organization?.id, team?.id])

  return (
    <OPageWrapper breadcrumbs={breadCrumbs} isLoading={isUserLoading}>
      <MTabs
        tabs={TABS}
        activeTab={activeTab}
        onTabChange={newTab => navigate(newTab.id)}
        className="mb-6 lg:mb-12"
      />

      <Routes>
        <Route path="program" element={<OAssignContentByUser user={user} />} />
        <Route
          path="timeline"
          element={
            <OUserTimeline
              user={{
                ...user,
                didJoinOn: user?.didJoinOn ? user.didJoinOn.seconds * 1000 : 1677452400000,
              }}
            />
          }
        />
        <Route
          path="calendar"
          element={
            <OUserCalendar
              user={{
                ...user,
                didJoinOn: user?.didJoinOn ? user.didJoinOn.seconds * 1000 : 1677452400000,
              }}
            />
          }
        />
        <Route path="providers-activity" element={<OProviderActivity />} />
        <Route path="questionnaires" element={<OQuestionnaires user={user} />} />

        <Route
          path="info"
          element={
            <OUserDetailsInfo
              user={user}
              isLoading={isUserLoading}
              onUserDeleted={handleUserDeleted}
              allowedFields={{ phoneNumber: true, canSendSMSNotifications: true }}
            />
          }
        />
        <Route path="*" element={<Navigate to="info" replace />} />
      </Routes>
    </OPageWrapper>
  )
}

export default PUser
